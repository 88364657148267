import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_8/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "chapter_4/sub_8/mobile/4-8-3-bg.png" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      modal4831:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "organizations-modals"}, selector: {eq: "modal4831"}}) {
        body
        frontmatter {
          name
        }
      },
      modal4832:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "organizations-modals"}, selector: {eq: "modal4832"}}) {
        body
        frontmatter {
          name
        }
      },
      modal4833:  mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "organizations-modals"}, selector: {eq: "modal4833"}}) {
        body
        frontmatter {
          name
        }
      },
    }
  `);
  return <Template query={query} />;
};

export default Slide;
