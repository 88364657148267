import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_8/slide4';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    col1: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-8-4-col1"}}) {
      body
    },
    col2: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-8-4-col2"}}) {
      body
    },
    col3: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-8-4-col3"}}) {
      body
    },
    caption:   mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-8-4-caption"}}) {
        body
    }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
